@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  @apply overflow-x-hidden;
}

/* Add these new styles for the sidebar to ensure it's on top of the header */
.app .sidenav {
  position: fixed;
  z-index: 1000 !important; /* Force higher z-index than header */
  top: 0;
  left: 0;
  height: 100vh;
}

/* Ensure header has a proper z-index but lower than sidenav */
.app .header {
  z-index: 50;
}

.container {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
}

.table th:first-child {
  position: unset;
  left: unset;
  z-index: unset;
}

.custom-dropdown {
  z-index: 999; /* Set your desired z-index value here */
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 65px;
}

.stickyHeader {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1;
}

.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #000 transparent #555 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

.custom-datetime .form-control {
  width: 100%;
}

.tooltip::before {
  white-space: pre-wrap;
}

@media (max-width: 768px) {
  .headline-mobile {
    font-size: 3rem;
    /* Adjust this value based on your desired mobile font size */
  }
}

body {
  margin: 0;
  background: #f1f2f5;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
